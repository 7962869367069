@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page-info__cta .page-info__cta__links ul li a {
    float: none;
    position: absolute;
    right: 0;
  }

  .page-info__cta .page-info__cta__search input {
    height: 35px;
  }

  footer .footer .footer__top .footer__right form input {
    height: 45px;
  }

  section.contact .contact__left input {
    height: 41px;
  }

  header .header-cta {
    margin-left: 50px;
  }
}
